import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Switch, TableCell, TableRow, Text } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { HyperLink } from '@/components/ui-parts/Link';
import { TALENT_DETAIL_PATH } from '@/constants/routes';
import { ENGAGEMENT_INTENT_LABEL } from '@/constants/talent/talentProfile';
import { AgentScoutModal } from '@/features/talent/components/list/modal/AgentScoutModal';
import { ScoutModal } from '@/features/talent/components/list/modal/ScoutModal';
import { useTalentPool } from '@/features/talent/repository/useTalentPool';
import { Talent } from '@/gql/graphql';
import { useResultHandler } from '@/hooks/useResultHandler';
import { formatDate } from '@/lib/dateformat';

const SKillLabel: Record<string, string> = {
  position: '職種',
  language: '言語',
  framework: 'フレームワーク',
  middleware: 'ミドルウェア',
  infra: 'インフラ',
};

type Skill = {
  id: string;
  name: string;
};

type Props = {
  row: Talent;
};

const scoutBadgeMap = {
  notScouted: <Badge variant="outlined" label="過去スカウトなし" />,
  scouted: <Badge variant="outlined" intention="primary" label="過去スカウトあり" />,
};

export const TalentTableRow = (props: Props) => {
  const actions = useTalentPool();
  const { onError, onSuccess } = useResultHandler();

  const talent = props.row;
  const skills = props.row.talentProfile.talentProfileMasterEngineerSkills;
  const categorySKills = skills.reduce(
    (acc, curr) => {
      if (!acc[curr.category.id]) {
        acc[curr.category.id] = [];
      }

      acc[curr.category.id].push(curr);
      return acc;
    },
    { position: props.row.talentProfile.talentProfileMasterEngineerPositions } as Record<string, Skill[]>,
  );

  const stacksLength = (stacks: Skill[]) => stacks.length;
  const limitStacks = (stacks: Skill[]) => stacks.slice(0, 3);
  const overCountStacks = (stacks: Skill[]) => stacksLength(stacks) - 3;

  const workHistories = props.row.talentProfile.talentProfileWorkHistories;
  const draftsLength = workHistories.length;
  const limitWorkHistories = workHistories.slice(0, 2);
  const overCountDrafts = draftsLength - 2;

  const id = talent.id;

  const scoutStatus = props.row.scouts.length > 0 ? 'scouted' : 'notScouted';

  const handleTalentPool = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      try {
        await actions.onCreate(props.row.id);
        onSuccess('検討中に追加しました');
      } catch (e) {
        onError(e, '検討中に追加できませんでした');
      }
    } else {
      try {
        await actions.onDelete(props.row.id);
        onSuccess('検討中から削除しました');
      } catch (e) {
        onError(e, '検討中から削除できませんでした');
      }
    }
  };

  return (
    <TableRow key={id}>
      <TableCell className="min-w-40 w-1/6 text-left align-top">
        <div className="flex flex-1 flex-col justify-between gap-4">
          {/* {talent.talentProfile.imageSrc && (
            <img
              src={talent.talentProfile.imageSrc}
              alt="プロフィール画像"
              width={400}
              height={400}
              className="h-[80px] w-[80px] rounded-full"
            />
          )} */}
          <div className="flex flex-col">
            <HyperLink
              to={TALENT_DETAIL_PATH.replace(':userId', talent.id)}
              size="l"
              bold
              label={`${talent.name} 様`}
              underline
            />
          </div>
          {scoutBadgeMap[scoutStatus]}
        </div>
      </TableCell>

      <TableCell className="w-1/6 text-left align-top">
        <div className="flex flex-col gap-2">
          {Object.keys(SKillLabel).map((key) => {
            const skills = categorySKills[key];
            if (!skills || skills.length === 0) {
              return null;
            }
            return (
              <div key={key} className="flex flex-row gap-2">
                <Text size="r" weight="bold" className="min-w-32">
                  {SKillLabel[key]}
                </Text>
                <div className="flex flex-row gap-2">
                  {skills.length > 3 ? (
                    <>
                      {limitStacks(skills).map((stack) => {
                        return (
                          <Badge
                            className="whitespace-nowrap [&_span]:whitespace-nowrap"
                            key={stack.id}
                            intention="shade"
                            label={stack.name}
                            variant="outlined"
                          />
                        );
                      })}
                      <Link to={TALENT_DETAIL_PATH.replace(':userId', id)}>
                        <Badge label={`+${overCountStacks(skills)}`} />
                      </Link>
                    </>
                  ) : (
                    skills.map((stack) => {
                      return (
                        <Badge
                          key={stack.id}
                          className="whitespace-nowrap"
                          intention="shade"
                          label={stack.name}
                          variant="outlined"
                        />
                      );
                    })
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </TableCell>

      <TableCell className="min-w-48 w-1/6 text-left align-top">
        <div className="grid grid-cols-1 gap-1">
          {draftsLength > 2 ? (
            <>
              {limitWorkHistories.map((item) => {
                return (
                  <div key={item.companyName} className="flex flex-col">
                    <HyperLink
                      to={TALENT_DETAIL_PATH.replace(':userId', id)}
                      size="r"
                      label={item.companyName}
                      underline
                    />
                    <Text size="r">
                      {formatDate(item.periodFrom)} - {formatDate(item.periodTo)}
                    </Text>
                  </div>
                );
              })}
              <Link to={TALENT_DETAIL_PATH.replace(':userId', id)}>
                <Badge label={`+${overCountDrafts}`} />
              </Link>
            </>
          ) : (
            workHistories.map((item, i) => {
              return (
                <div key={i} className="flex flex-col">
                  <HyperLink
                    to={TALENT_DETAIL_PATH.replace(':userId', id)}
                    size="r"
                    label={item.companyName}
                    underline
                  />
                  <Text size="r">
                    {formatDate(item.periodFrom)} - {formatDate(item.periodTo)}
                  </Text>
                </div>
              );
            })
          )}
        </div>
      </TableCell>

      <TableCell className="min-w-72 w-1/6 text-left align-top">
        <LabeledDList
          details={[
            {
              label: '希望時給',
              content: (
                <Text className="whitespace-nowrap" size="r">
                  {talent.talentProfile.priceHourlyMin?.toLocaleString() || '-'} -{' '}
                  {talent.talentProfile.priceHourlyMax?.toLocaleString() || '-'} 円 / 時
                </Text>
              ),
            },
            {
              label: '希望稼働時間(週)',
              content: (
                <Text className="whitespace-nowrap" size="r">
                  {talent.talentProfile.weeklyWorkingDayMin?.toLocaleString() || '-'} -{' '}
                  {talent.talentProfile.weeklyWorkingDayMax?.toLocaleString() || '-'} 日 / 週
                </Text>
              ),
            },
            {
              label: '案件意欲',
              content: (
                <Text size="r">
                  {talent.talentProfile.engagementIntent
                    ? ENGAGEMENT_INTENT_LABEL[talent.talentProfile.engagementIntent]
                    : '-'}
                </Text>
              ),
            },
          ]}
        />
      </TableCell>

      <TableCell className="min-w-28 w-1/6 text-left align-top">
        <div className="grid w-full grid-cols-1 gap-2">
          <Switch onChange={handleTalentPool} label="検討中" checked={props.row.hasPool} />
          {/* <AgentScoutModal talent={talent} /> */}
          <ScoutModal talent={talent} />
        </div>
      </TableCell>
    </TableRow>
  );
};
